
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import Restaurateur from "@/models/Restaurateur";
import {AccountTitle} from "@/enum/AccountTitle.enum";
import {AccountPosition} from "@/enum/AccountPosition.enum";
import RestauranteurRepository from "@/api/repositories/RestauranteurRepository";
import {AUTH_STORE_NAME, AuthStoreMutations} from "@/store/auth.store";
import {namespace} from "vuex-class";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    updatingUser: {
      firstName: {
        required
      },
      lastName: {
        required
      },
    }
  }
})
export default class ChangeGeneralComponent extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Mutation(AuthStoreMutations.SET_USER)
  private setUser!: (user: Restaurateur) => void;

  @Prop({required: true})
  private user!: Restaurateur;

  private updatingUser: Restaurateur = new Restaurateur();

  created() {
    this.updatingUser = Restaurateur.parseFromObject({...this.user});
  }

  /**
   * different options of titles for the user
   * @private
   */
  private titleOptions = [
    { value: AccountTitle.MALE, label: this.$t('GENERAL.TITLES.MALE')},
    { value: AccountTitle.FEMALE, label: this.$t('GENERAL.TITLES.FEMALE')},
    { value: AccountTitle.DIVERSE, label: this.$t('GENERAL.TITLES.DIVERSE')},
  ]

  /**
   * different options of the position for the user
   * @private
   */
  private positionOptions = [
    { value: AccountPosition.MANAGER, label: this.$t('GENERAL.POSITIONS.MANAGER')},
    { value: AccountPosition.OWNER, label: this.$t('GENERAL.POSITIONS.OWNER')},
    { value: AccountPosition.EMPLOYEE, label: this.$t('GENERAL.POSITIONS.EMPLOYEE')},
    { value: AccountPosition.OTHER, label: this.$t('GENERAL.POSITIONS.OTHER')},
  ]

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;
      const response = await RestauranteurRepository.updateRestauranteur(this.updatingUser);
      const updatedUser = Restaurateur.parseFromObject({...response.data});
      this.setUser(updatedUser);

      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_GENERAL_SUCCESS');
      this.dismiss(true);
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_GENERAL_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
